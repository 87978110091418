// Variable overrides
// Need to be loaded first, see https://bulma.io/documentation/customize/variables/
@import "_variables";            // Overrides for Bulma variables
// @import "_darkVariables";       // Dark theme overrides for Bulma variables

// Bulma
@import "../bulma/bulma.sass";  // Bulma scss

// Theme scss
//
// Ordered according to the order of the html elements
// @import "_darkOverrides";     // Dark theme
@import "_navbar";      
@import "_pageNav";     
@import "_floatingHero";
@import "_boxHero";     
@import "_postHeader";  
@import "_textBanner";  
@import "_features";    
@import "_stats";      
@import "_language";    
@import "_misc";        
@import "_feedback";    
@import "_terms";    
@import "_media";    
@import "_resourceKey";    

:root {
  --navbar-height: #{$navbar-height};
}