.pageNavbar {
  user-select: none;
  background: #fcfcfd;
  position: sticky;
  top: 0;
  overflow: auto;
  max-height: 100vh;
  flex: 0 0 280px;
  padding: 24px 12px;
  .menu-list {
    font-size: 13px;
    margin-right: 0;
    a {
      padding-right: 0;
      &.active {
        background: #00000011; } } }
  .menu-list .menu-list {
    display: none;
    &.is-expanded {
      display: block; } } }

.sidebar-burger {
  display: none; }

.testSiteBanner {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background: repeating-linear-gradient(45deg,#cacaca22,#cacaca22 10px,#87878722 10px,#87878722 20px); }

@media screen and (max-width: $tablet) {
  .documentation.is-active {
    display: block;
    .pageNavbar {
      display: block;
      border: 1px solid #ddd;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 300px;
      right: 0;
      max-width: 100%;
      z-index: 100;
      padding-bottom: 100px;
      box-shadow: 0 0 500px 1000px #00000055; } }
  .pageNavbar {
    display: none; }
  .sidebar-burger {
    display: block;
    position: fixed;
    right: 0;
    width: 2rem;
    bottom: 0;
    z-index: 200;
    width: 2.25rem;
    height: 2.25rem;
    box-shadow: 0 0 3px 2px #88888840;
    margin: 1rem;
    background-color: $primary;
    color: findColorInvert($primary);
    &:hover {
      background-color: $primary;
      color: findColorInvert($primary); } } }
.documentation {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 555px;
  overflow-wrap: anywhere;
  &-content {
    width: 100%;
    min-width: 0; } }
