@if $navbar-brand-background-color {
  $navbar-brand-ink: findColorInvert($navbar-brand-background-color) !default;
  .navbar-logo_text {
    color: $navbar-brand-ink!important; }
  .navbar-brand a.navbar-item, .navbar-brand a.navbar-item:hover {
    background: $navbar-brand-background-color!important; } }

.navbar {
  box-shadow: 0px 2px 8px rgba(0,0,0, .1);
  &--floating {
    position: absolute;
    width: 100%;
    box-shadow: none; } }

@media screen and (max-width: $navbar-breakpoint) {
  .navbar {
    // background: $navbar-background-color!important
    &--hasWhiteText {
      .navbar-logo_text {
        color: white; }
      .navbar-burger {
        color: white; } } } }

@media screen and (min-width: $navbar-breakpoint) {
  .navbar {
    // Removed again as it broke menu overflow
    // https://github.com/gbif/jekyll-hp-base-theme/issues/1#issuecomment-779634066
    // .navbar-menu
    //   flex-shrink: 1 // added to handle menu bars with many items
    // .navbar-start
    //   overflow: auto // added to handle menu bars with many items
    &--hasWhiteText {
      .navbar-logo_text {
        color: white; }
      .navbar-start {
        .navbar-link:not(.is-arrowless)::after {
          border-color: white; }
        >.navbar-item, .navbar-link {
          color: white; }
        .has-dropdown {
          color: white; } } } }
  .navbar--hasWhiteText {
    //a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active, .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link
    .navbar-top-item:hover {
      background-color: #00000033!important;
      >a {
        background-color: transparent!important; } } } }



.toc__removeOnMobile {
  display: none; }

.article--toc {
  margin-left: auto;
  margin-right: auto; }

@include desktop {
  .article--toc > div {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap; }
  .toc-wrapper {
    display: block;
    flex: none;
    width: 33.3333%;
    max-width: 300px; }
  .article--toc .article {
    flex: none;
    width: 66.6666%; } }

.toc {
  @extend .menu;
  padding: 24px 12px;
  padding-top: 0;
  position: sticky;
  top: 0;
  overflow: auto;
  max-height: 100vh;
  a {
    border-left: 3px solid transparent;
    border-radius: 0!important;
    &.active {
      border-left: 3px solid deepskyblue; } } }
.toc_header {
  @extend .menu-label;
  position: sticky;
  top: 0;
  background: white;
  padding: 24px 0 1em 0;
  margin-bottom: 0!important; }
.toc ul {
  @extend .menu-list;
  font-size: 13px; }

@include until($navbar-breakpoint) {
  .navbar-end {
    border-top: 1px solid #eee;
    a {
      display: inline-block; }
    .button {
      display: none; } } }

@include from($navbar-breakpoint) {
  .navbar-content {
    display: flex; } }

.navbar-logo_text {
  font-weight: 900;
  font-size: 120%; }

