.textBanner {
  @extend .floatingHero;
  min-height: auto;
  .feature-img {
    background-size: auto; }
  .feature-content {
    max-width: 100%;
    width: 45rem;
    padding: 5rem 1rem;
    margin: 0 auto;
    font-size: 120%; }
  .feature-title {
    font-weight: bold;
    font-size: 2rem; }
  .feature-description {
    font-size: 100%; } }
