@import "theme/_main";

// You can add additional styling here
.navbar-logo .navbar-logo_img {
  max-height: 3rem;
  margin-right: 24px;
}

.home.floatingHero {
  .feature-content {
    text-align: start;
    margin-left: auto;
    margin-right: auto;
    width: $navbar-breakpoint;
    max-width: 100%;
  }
   .feature-description {
    margin: initial;
    padding: 0;
    width: 500px;
    p {
      font-weight: 500
    }
  }  
}

.iframe-box {
  padding: 24px;
  iframe {
    @extend .box;
    width: 100%;
    max-height: calc(100% - 68px);
    margin-left: auto;   /* Automatic margin from left */ 
    margin-right: auto; /* Automatic margin from right */ 
    border: 1px solid #00000011;
    padding: 0;
  }
}

.ipts_in_canada {
  .feature-img img, .feature-img video, .feature-img picture {
    object-fit: contain;
    padding: 12px;
  }
}

.footer-description img {
    margin-bottom: 48px;
}
