$stats-width: 200px !default;
$stats-gap: 12px !default;

.statistics {
  background: #edf3f3;
  padding-top: 3rem;
  padding-bottom: 3rem; }
.statistics-intro {
  text-align: center;
  max-width: 45rem;
  margin: 0 auto;
  font-size: 120%;
  padding-bottom: 3rem; }

.statistics-cards {
  @extend .container;
  @extend .is-fluid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -($stats-gap) auto;
  padding: 0 12px; }

.statistic {
  flex: 0 0 $stats-width;
  max-width: 100%;
  margin: $card-gap;
  overflow: hidden;
  text-align: center;
  .feature-img {
    width: 50%;
    margin: auto;
    background-size: contain;
    img, picture {
      width: 100%;
      position: relative; } }
  .feature-content {
    padding: 12px; }
  .feature-description {
    color: #5e5e5e; }
  .feature-title {
    margin-top: 0!important; } }
